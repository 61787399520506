<template>
  <div>
    <v-card class="mb-5" outlined color="white">
      <v-breadcrumbs :items="items" large></v-breadcrumbs>
    </v-card>

    <div class="mb-5">
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <div>
      <v-row>
        <v-col cols="12" md="6">
          <v-card class="px-3 py-2">
            <span class="font-weight-black">Form Pengaduan</span>

            <div class="mt-3">
              <v-form ref="form" lazy-validation class="mb-5">
                <v-row no-gutters>
                  <v-col cols="11">
                    <v-select
                      :items="refJP"
                      item-value="jp_id"
                      item-text="jp_nama"
                      return-object
                      @change="selectJP"
                      label="Jenis Pengaduan"
                      :rules="jpRules"
                    ></v-select>
                  </v-col>
                  <v-col cols="1" class="my-auto">
                    <v-menu offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" v-bind="attrs" v-on="on">
                          <v-icon color="primary">
                            mdi-information
                          </v-icon>
                        </v-btn>
                      </template>

                      <v-list class="px-5 py-2">
                        <span class="subtitle-2">Keterangan</span>
                        <div class="text--disabled">
                          {{ jp_ket }}
                        </div>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-text-field
                  maxlength="150"
                  autocomplete="off"
                  label="Judul Pengaduan"
                  :rules="judulRules"
                  v-model="pengaduan_judul"
                ></v-text-field>

                <v-textarea
                  label="Isi Pengaduan"
                  :rules="pengaduanRules"
                  v-model="pengaduan_isi"
                ></v-textarea>

                <v-divider class="my-3"></v-divider>

                <v-btn
                  v-if="btnLoading"
                  color="primary"
                  depressed
                  block
                  @click="simpan()"
                >
                  KIRIM PENGADUAN
                </v-btn>

                <v-btn v-else color="primary" loading depressed block>
                  KIRIM PENGADUAN
                </v-btn>
              </v-form>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="my-auto hidden-md-and-down">
          <v-img
            src="@/assets/gambar/form-pengaduan.svg"
            max-width="400"
            class="mx-auto"
          ></v-img>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import getRef from "@/helper/getRef.js";

import refreshView from "@/store/pengaduanForm/viewForm";

export default {
  name: "PengaduanForm",

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },
      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },
      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },
      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    valid: true,
    btnLoading: true,

    refJP: [],

    jp_ket: "",
    jp_id: "",
    jpRules: [(v) => !!v || "Tidak Boleh Kosong!"],

    pengaduan_judul: "",
    judulRules: [(v) => !!v || "Tidak Boleh Kosong!"],

    pengaduan_isi: "",
    pengaduanRules: [(v) => !!v || "Tidak Boleh Kosong!"],

    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Pengaduan",
        disabled: false,
        href: "/pengaduan/beranda",
      },
      {
        text: "Form",
        disabled: false,
        href: "/pengaduan/form",
      },
    ],
  }),

  async mounted() {
    this.refJP = await getRef.JP();
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    default() {
      this.$refs.form.reset();
      this.jp_id = "";
      this.pengaduan_judul = "";
      this.pengaduan_isi = "";
    },

    simpan() {
      this.btnLoading = false;
      this.validate();
      if (this.validate()) {
        const data = {
          pengaduan_judul: this.pengaduan_judul,
          pengaduan_isi: this.pengaduan_isi,
          jp_id: this.jp_id,
        };

        const url = process.env.VUE_APP_API_BASE + "pengaduan";
        this.http
          .post(url, data)
          .then((response) => {
            this.btnLoading = true;
            if (response.data.success) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("berhasilAlert", true);
              refreshView.commit("gagalAlert", false);
              refreshView.commit("success", response.data.success);
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", response.data.success);
            }
            this.default();
            window.scrollTo(0, 0);
          })
          .catch((error) => {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.btnLoading = true;
            this.default();
          });
      }
    },

    selectJP(value) {
      this.jp_id = value.jp_id;
      this.jp_ket = value.jp_ket;
    },
  },
};
</script>
